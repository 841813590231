// Purpose: Main App Component
import React from 'react';
import backgroundVideo from './background.mp4';
import './App.css';

function App() {
  return (
    <div className="App"> 
      {/* Header Section*/}
      {/* This section will include the nav bar, donate button, and text logo aligned left*/}
      <header className="App-header">   
        <video autoPlay muted loop className="background-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag
        </video>
        
        <div className='header-content'>  
         <div className='header-title grid'>
          <span class="font-merriweather font-bold text-3xl">Missouri State Society</span>
          <span class="font-bodini italic text-2xl justify-self-end">of Washington, D.C.</span>
         </div>
          {/* Comment out for production
          <div className='header-buttons'>
            <button className="btn btn-primary">Home</button>
            <button className="btn btn-primary">Button 2</button>
            <button className="btn btn-primary">Button 3</button>
            <button className="btn btn-primary">Button 4</button>
          </div>
           */}
        </div> 
        <div className="header-purpose mx-auto max-w-3xl">
        Coming soon.
        </div>
      </header> 

      {/* comment out for production
      <main className="App-body">
        <section>
        <h2>Test Section</h2>
        <p>This is the body Content</p>
        <button className="btn btn-primary">Test Button</button>
        </section>
      </main>


      <footer className="App-footer">
        <p>Footer Text</p>
        <a href="https://www.facebook.com">Facebook</a>
        <a href="https://www.twitter.com">Twitter</a>
        <a href="https://www.instagram.com">Instagram</a>
      </footer>
      */}

    </div>
  );
}

export default App;
